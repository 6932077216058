<!--
#***********************************************
#
#      Filename: src/views/Contract/ContractTemplate.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 合同模板
#        Create: 2021-10-28 16:48:58
# Last Modified: 2021-11-29 15:23:55
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增合同模板')"
      @refresh="handleRefresh" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop" />
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop">
        <template slot-scope="scope">
          {{ formOperName(scope.row.createAid) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div class="gz-text-btn gz-text-btn-yellow" @click="edit(scope.row, '修改业主', beforeEdit)">
              <i class="el-icon-edit"></i>
              编辑
            </div>
            <el-popconfirm title="确定删除本条数据吗？" @confirm="handleDelete(scope.row.id, '删除业主', handleDel)">
              <div slot="reference" class="gz-text-btn gz-text-btn-red">
                <i class="el-icon-delete"></i>
                删除
              </div>
            </el-popconfirm>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog"
      @close="handleClose">
      <el-form ref="form" label-width="100px" :model="actionDataItem" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="模板名称:" prop="name">
              <el-input v-model="actionDataItem.name" placeholder="请输入模板名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="模板类型:" prop="vipType">
              <el-select v-model="actionDataItem.vipType" placeholder="请选择模板类型">
                <el-option v-for="(item, index) in vipTypeList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="描述" prop="desc">
              <el-input v-model="actionDataItem.desc" type="textarea" placeholder="请输入合同描述信息" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider />
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="上传文件" prop="remark">
              <el-upload
                class="upload-demo"
                :multiple="false"
                :on-remove="handleRemove"
                :before-upload="beforeUpload"
                :http-request="upload"
                :limit="1"
                :file-list="fileList"
                action="#">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传pdf文件,文件大小不超过2M</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import { tpList, tpAdd, tpUpdate, deleteItem } from '@/api/agreement'
  import { list } from '@/api/vipSettings'
  import { fileUpload } from '@/api/common'

  export default {
    name: 'ContractTemplate',

    components: { ContentHeader },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          vipType: '',
          desc: '',
          tplFile: ''
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          name: [{ required: true, message: '模板名称不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '合同模板名称'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: true,
            exportDataShow: false,
            more: []
          },
          dropDownFilterArray: {}
        },

        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '模板名称',
            isShow: true,
            width: '400',
            align: 'center',
            prop: 'name'
          },
          {
            label: '模板类型',
            isShow: true,
            width: '400',
            align: 'center',
            prop: 'vipTypeName'
          },
          {
            label: '描述',
            isShow: true,
            width: '400',
            align: 'center',
            prop: 'desc'
          },
          {
            label: '创建时间',
            isShow: true,
            width: '400',
            align: 'center',
            prop: 'createTime'
          },
          {
            label: '操作人员',
            isShow: true,
            width: '400',
            align: 'center',
            prop: 'createAid'
          }
        ],

        /**
         * 月租类型列表
         */
        vipTypeList: [],

        /**
         * 上传文件列表
         */
        fileList: [],
        permissions: {
          create: 'agreement:tpl:info:create',
          info: 'agreement:tpl:info:info',
          delete: 'agreement:tpl:info:delete',
          update: 'agreement:tpl:info:update',
          export: 'agreement:tpl:info:export',
          import: 'agreement:tpl:info:import'
        }
      }
    },

    mounted() {
      this.getList()
      this.getVipTypeList()
    },

    methods: {
      handleClose() {
        this.$refs.form.resetFields()
        this.fileList = []
      },
      /**
       * 编辑预处理
       */
      beforeEdit(data) {
        this.actionDataItem.tplFile = decodeURIComponent(data.tplFile)
        this.fileList = [
          {
            name: decodeURIComponent(data.tplFile),
            url: decodeURIComponent(data.tplFile)
          }
        ]
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = this.pageSize
        this.filterData.searchStr && (queryData.searchKw = this.filterData.searchStr)
        console.log(queryData)
        tpList(_this.pid, queryData)
          .then((res) => {
            if (res.result) {
              console.log(res)
              _this.dataList = res.data.records
              _this.total = res.data.total
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 获取月租类型列表数据
       */
      getVipTypeList() {
        const _this = this

        // 组装查询条件
        const queryData = {}
        queryData.current = this.currentPage
        queryData.size = 9999
        queryData.parkingId = this.pid

        list(queryData)
          .then((res) => {
            if (res.result) {
              _this.vipTypeList = res.data.records
            } else {
              _this.msgError(res.message)
            }
          })
          .catch((err) => {
            console.error('月租类型列表查询出错', err)
            _this.msgError(err)
          })
      },
      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            _this.actionDataItem.tplFile = encodeURIComponent(_this.actionDataItem.tplFile)
            if (_this.modalObject.currentAction === 'edit') {
              tpUpdate(_this.actionDataItem.id, _this.actionDataItem)
                .then((response) => {
                  if (response.result) {
                    _this.noticeSuccess(response.message)
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(response.message)
                  }
                })
                .catch((err) => {
                  console.error('合同编辑请求出错', err)
                })
            } else {
              delete _this.actionDataItem.id
              tpAdd(_this.pid, _this.actionDataItem)
                .then((res) => {
                  if (res.result) {
                    _this.noticeSuccess(res.message)
                    _this.modalObject.status = false
                    _this.handleRefresh()
                  } else {
                    _this.noticeError(res.message)
                  }
                })
                .catch((err) => {
                  console.error('合同添加请求出错', err)
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        deleteItem(id)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.msgError(err.message)
          })
      },

      /**
       * 上传文件前文件类型与文件大小的校验
       */
      beforeUpload(file) {
        const fileExt = file.name.replace(/.+\./, '')
        if (['pdf'].indexOf(fileExt.toLowerCase()) === -1) {
          this.msgError('只能上传pdf文件')
          return false
        }
        const fileSize = file.size / 1024 / 1024
        if (fileSize > 2) {
          this.msgError('文件大小超过2M')
          return false
        }
      },

      /**
       * 上传文件
       */
      upload(params) {
        const _this = this
        const _file = params.file

        // 通过formData对象上传文件
        const formData = new FormData()
        formData.append('file', _file)

        const queryData = {}
        queryData.type = 0

        fileUpload(formData, queryData)
          .then((res) => {
            if (res.result) {
              _this.noticeSuccess('上传成功')
              _this.actionDataItem.tplFile = res.data.url
            } else {
              _this.noticeError('上传失败:' + res.message)
            }
          })
          .catch((err) => {
            _this.noticeError('文件上传请求失败', err)
          })
      },

      /**
       * 删除文件列表中的文件
       */
      handleRemove() {
        this.actionDataItem.tplFile = ''
        this.fileList = []
      }
    }
  }
</script>
