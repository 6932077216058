/*
#***********************************************
#
#      Filename: src/api/agreement/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 合同相关API
#        Create: 2021-11-29 09:21:07
# Last Modified: 2021-11-29 15:04:07
#***********************************************
*/
import request from '@/utils/request'
import qs from 'qs'

// 合同详情
export function info(id) {
  return request({
    url: '/agreement/info/' + id,
    method: 'get'
  })
}

// 合同列表
export function list(id, data) {
  return request({
    url: '/agreement/list/' + id,
    method: 'get',
    params: data
  })
}

// 删除模板
export function deleteItem(id) {
  return request({
    url: '/agreement/rpl/delete/' + id,
    method: 'post'
  })
}

// 创建合同模板
export function tpAdd(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/agreement/tpl/create/' + id,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 模板列表
export function tpList(id, data) {
  return request({
    url: '/agreement/tpl/list/' + id,
    method: 'get'
  })
}

// 更新模板
export function tpUpdate(id, data) {
  const formData = qs.stringify(data)
  return request({
    url: '/agreement/tpl/update/' + id,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
