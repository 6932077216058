/*
#***********************************************
#
#      Filename: src/api/vipSettings/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 月租设置
#        Create: 2021-11-29 14:18:49
# Last Modified: 2021-11-29 14:26:38
#***********************************************
*/
import request from '@/utils/request'

// 添加
export function add(data) {
  return request({
    url: '/setting/vip/type/create',
    method: 'post',
    params: data
  })
}

// 删除
export function deleteItem(id, data) {
  return request({
    url: '/setting/vip/type/delete/' + id,
    method: 'post',
    params: data
  })
}

// 详细信息
export function info(id) {
  return request({
    url: '/setting/vip/type/info/' + id,
    method: 'get'
  })
}

// 列表
export function list(data) {
  return request({
    url: '/setting/vip/type/list',
    method: 'get',
    params: data
  })
}

// 更新月租类型
export function update(id, data) {
  return request({
    url: '/setting/vip/type/update/' + id,
    method: 'post',
    params: data
  })
}
